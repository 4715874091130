import { Grid, ResponsiveText } from '@eventbrite/wagtail-components';
import uniqueId from 'lodash/uniqueId';
import React from 'react';
import { useTheme } from '../../../utils/context/theme-context';
import CTABlock, {
    CTABlockInterface,
} from '../../sharedComponents/cta-block/CTABlock';
import { CTA_BLOCK_LARGE_SIZE } from '../../sharedComponents/cta-block/utils';
import { BaseModuleProps } from '../types';
import './featureBlock.scss';

export interface FeatureBlockCardImageBlockInterface {
    imageUrl: string;
    altText: string;
}

export interface FeatureBlockCardInterface {
    image?: FeatureBlockCardImageBlockInterface;
    title?: string;
    subheader?: string;
    description?: string;
    style?: any;
    isIcon: boolean;
}

export interface FeatureBlockPropsInterface {
    headline?: string;
    description?: string;
    cardGroup: FeatureBlockCardInterface[];
    ctaBlock?: CTABlockInterface;
    cardTextAlignment: 'left' | 'center';
    isIcon: boolean;
    useH1?: boolean;
}

export const IndividualCard = ({
    image,
    title,
    subheader,
    description,
    style,
    variant,
    isIcon,
}: FeatureBlockCardInterface & BaseModuleProps): JSX.Element => {
    const baseOptions = { variant };
    const theme = useTheme();
    const [textClass] = theme.generateStyles('text', baseOptions);
    return (
        <div
            className="feature-block-module__container__feature-cards__card"
            style={style}
        >
            <div
                className={`feature-block-module__container__feature-cards__card__content ${textClass}`}
            >
                {image?.imageUrl &&
                    (isIcon ? (
                        <div
                            data-testid="icon-container"
                            className="feature-block-module__container__feature-cards__card__content__icon-container"
                        >
                            <img src={image?.imageUrl} alt={image?.altText} />
                        </div>
                    ) : (
                        <div
                            data-testid="image-container"
                            className="feature-block-module__container__feature-cards__card__content__image-container"
                        >
                            <img src={image?.imageUrl} alt={image?.altText} />
                        </div>
                    ))}

                {title && (
                    <ResponsiveText
                        base={{
                            fontSize: 52,
                            lineHeight: 64,
                        }}
                        className="feature-block-module__container__feature-cards__card__content__title"
                    >
                        {title}
                    </ResponsiveText>
                )}

                {subheader && (
                    <ResponsiveText
                        base={{
                            fontSize: 20,
                            lineHeight: 28,
                        }}
                        className="feature-block-module__container__feature-cards__card__content__subheader"
                    >
                        <span
                            //eslint-disable-next-line react/no-danger
                            dangerouslySetInnerHTML={{ __html: subheader }}
                        ></span>
                    </ResponsiveText>
                )}

                {description && (
                    <ResponsiveText
                        base={{
                            fontSize: 14,
                            lineHeight: 20,
                        }}
                        className="feature-block-module__container__feature-cards__card__content__description"
                    >
                        {description}
                    </ResponsiveText>
                )}
            </div>
        </div>
    );
};

const FeatureBlockModule = ({
    headline,
    description,
    cardGroup,
    ctaBlock,
    cardTextAlignment,
    variant,
    isIcon,
    useH1,
}: FeatureBlockPropsInterface & BaseModuleProps) => {
    const baseOptions = { variant };
    const theme = useTheme();
    const [backgroundClass] = theme.generateStyles('background', baseOptions);
    const [textClass] = theme.generateStyles('text', baseOptions);
    const shouldIncludeCtaButton = ctaBlock?.url && ctaBlock?.callToAction;
    return (
        <section className={`feature-block-module ${backgroundClass}`}>
            <div className="feature-block-module__container module-landing-page__module-container-content">
                {headline && (
                    <ResponsiveText
                        base={{
                            fontSize: 52,
                            lineHeight: 40,
                        }}
                        small={{
                            fontSize: 32,
                            lineHeight: 40,
                        }}
                        component={useH1 ? 'h1' : 'h2'}
                        className={`feature-block-module__container__headline-description-container__headline ${textClass}`}
                    >
                        {headline}
                    </ResponsiveText>
                )}

                {description && (
                    <ResponsiveText
                        base={{
                            fontSize: 24,
                            lineHeight: 32,
                        }}
                        small={{
                            fontSize: 18,
                            lineHeight: 24,
                        }}
                        className={`feature-block-module__container__headline-description-container__description ${textClass}`}
                    >
                        {description}
                    </ResponsiveText>
                )}

                <Grid
                    className="feature-block-module__container__feature-cards"
                    style={{
                        textAlign: cardTextAlignment,
                        maxWidth: cardGroup.length > 4 ? '70rem' : '100%',
                    }}
                    base={{
                        columnCount:
                            cardGroup.length > 4 ? 3 : cardGroup.length,
                        gap: 26,
                        rowGap: 28,
                    }}
                    medium={{
                        columnCount: 2,
                    }}
                    small={{
                        columnCount: 1,
                    }}
                >
                    {cardGroup.map(
                        ({ image, title, subheader, description }) => (
                            <IndividualCard
                                image={image}
                                title={title}
                                subheader={subheader}
                                description={description}
                                key={uniqueId()}
                                variant={variant}
                                isIcon={isIcon}
                            />
                        ),
                    )}
                </Grid>

                {shouldIncludeCtaButton && (
                    <div className="feature-block-module__container__feature-cta__content">
                        <CTABlock
                            {...ctaBlock}
                            size={CTA_BLOCK_LARGE_SIZE}
                            themeVariant={variant}
                        />
                    </div>
                )}
            </div>
        </section>
    );
};

export default FeatureBlockModule;
